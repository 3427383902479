import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import withUser from '../redux/Hoc/withUser';
import { getRoofTopById } from '../utils/firebase';
import appImage from '../assets/image';
import Finance from '../components/Finance';
import { eventReactGTMModule } from '../utils/analytics';
import PartsandAccessoriesBanner from '../assets/image/parts-and-accessories-banner.jpeg';

const PartsAndAccessories = ({ user }) => {
  const [rooftopDetail, setRooftopDetail] = useState({});

  const handleOpenLink = (link, destination) => {
    const tagManagerArgs = {
      event: 'e_dealer_visit',
      area: 'parts',
      external_destination: destination
    };
    eventReactGTMModule(tagManagerArgs);
    window.open(link, '_blank');
  };

  useEffect(() => {
    async function onInit() {
      const response = await getRoofTopById(user.rooftop);
      setRooftopDetail(response);
    }
    onInit();
  }, []);
  return (
    <>
      <main className="lg:col-span-9 xl:col-span-9">
        <div className="w-full rounded-lg bg-white shadow flex flex-col mb-8 ">
          <div className="rounded-t-lg bg-[#F2F3F6] p-5 md:px-[68px] pt-[48px] pb-[50px] md:py-[90px] flex flex-col gap-6 relative">
            <img
              className="hidden md:block  bg-financing-banner financing-banner-height aspect-[6/4] absolute object-cover object-center top-0 right-0 rounded-tr-lg rounded-bl-[90px] md:rounded-bl-[120px]"
              src={PartsandAccessoriesBanner}
              alt="financing"
            />
            <div className="max-w-[320px]">
              <h2 className="text-2xl md:text-4xl leading-6 font-extrabold text-[#333333] mt-0 md:mt-0 mb-4 md:mb-8 ">
                Parts and Accessories
              </h2>
              <p className="text-base md:text-lg font-normal max-w-[420px] ">
                Explore genuine parts backed by warranty an installed by experts. Support your local
                community, and choose {rooftopDetail && rooftopDetail.name} for quality parts and
                accessories.
              </p>
            </div>
            <div className="border-[#5046E4] border-[3px] w-[180px] md:w-96 absolute bottom-[-6px] left-0 rounded-br-full" />
          </div>
          {rooftopDetail && rooftopDetail.dignify && (
            <div className="">
              <Finance
                rooftop={rooftopDetail}
                desktopHide
                customClassPending=""
                customClassApproved="md:px-[32px] md:pt-[16px] bg-white rounded-lg"
              />
            </div>
          )}

          <div className="rounded-lg flex flex-col gap-6 mb-0 md:mb-8" />
          <div className="rounded-lg p-8 hidden md:flex flex-col gap-6 mb-8 md:px-14 md:py-10">
            {!isEmpty(rooftopDetail) && (
              <>
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.accessories && (
                    <>
                      <div className="grid grid-cols-12 pl-10 pr-4">
                        <div className="col-span-9 flex flex-row gap-7 items-center">
                          <img className="w-8 h-8" src={appImage.CarRims} alt="" />
                          <div className="flex flex-col">
                            <p className="text-[22px] font-semibold">Accessories</p>
                            <span className="text-[15px] text-[#898989]">
                              Accessories and mods for your ride
                            </span>
                          </div>
                        </div>
                        <div className="col-span-3">
                          <button
                            className="cursor-pointer relative my-auto mr-0 flex flex-row gap-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            type="button"
                            onClick={() =>
                              handleOpenLink(
                                rooftopDetail.external_links.accessories,
                                'accessories'
                              )
                            }>
                            Shop Accessories{' '}
                            <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                    </>
                  )}
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.parts_order && (
                    <>
                      <div className="grid grid-cols-12 pl-10 pr-4">
                        <div className="col-span-9 flex flex-row gap-7 items-center">
                          <img className="w-8 h-8" src={appImage.CartIcon} alt="" />
                          <div className="flex flex-col">
                            <p className="text-[22px] font-semibold">Order Parts</p>
                            <span className="text-[15px] text-[#898989]">
                              Browse the parts department
                            </span>
                          </div>
                        </div>
                        <div className="col-span-3">
                          <button
                            className="cursor-pointer relative my-auto flex flex-row gap-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            type="button"
                            onClick={() =>
                              handleOpenLink(
                                rooftopDetail.external_links.parts_order,
                                'parts_order'
                              )
                            }>
                            Order Parts{' '}
                            <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                    </>
                  )}
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.finance && (
                    <>
                      <div className="grid grid-cols-12 pl-10 pr-4">
                        <div className="col-span-9 flex flex-row gap-7 items-center">
                          <img className="w-8 h-8" src={appImage.CouponIcon} alt="" />
                          <div className="flex flex-col">
                            <p className="text-[22px] font-semibold">Parts Coupons</p>
                            <span className="text-[15px] text-[#898989]">
                              Discounts on Service & Parts
                            </span>
                          </div>
                        </div>
                        <div className="col-span-3">
                          <button
                            className="cursor-pointer relative my-auto mr-0 flex flex-row gap-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            type="button"
                            onClick={() =>
                              handleOpenLink(rooftopDetail.external_links.finance, 'parts_coupons')
                            }>
                            Redeem Coupons{' '}
                            <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                    </>
                  )}
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.service_url && (
                    <>
                      <div className="grid grid-cols-12 pl-10 pr-4">
                        <div className="col-span-9 flex flex-row gap-7 items-center">
                          <img className="w-8 h-8" src={appImage.TireIcon} alt="" />
                          <div className="flex flex-col">
                            <p className="text-[22px] font-semibold">Tire Center</p>
                            <span className="text-[15px] text-[#898989]">
                              Browse the tires department
                            </span>
                          </div>
                        </div>
                        <div className="col-span-3">
                          <button
                            className="cursor-pointer relative my-auto mr-0 flex flex-row gap-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            type="button"
                            onClick={() =>
                              handleOpenLink(rooftopDetail.external_links.service_url, 'tires')
                            }>
                            Shop Tires{' '}
                            <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}
          </div>
          <div className="flex md:hidden flex-col gap-2.5 mb-8 md:px-14 md:py-10 mt-5 md:mt-12">
            {!isEmpty(rooftopDetail) && (
              <>
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.accessories && (
                    <div className="rounded-lg flex flex-col items-start justify-between p-5 gap-4">
                      <div className="flex flex-row gap-4 items-center">
                        <img className="w-10 h-10" src={appImage.CarRims} alt="" />
                        <div className="flex flex-col">
                          <p className="text-[22px] font-semibold">Accessories</p>
                          <span className="text-[15px] text-[#898989]">
                            Accessories and mods for your ride
                          </span>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                      <button
                        className="cursor-pointer mt-2 relative my-auto mr-0 flex flex-row gap-3 items-center justify-between px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        type="button"
                        onClick={() =>
                          handleOpenLink(rooftopDetail.external_links.accessories, 'accessories')
                        }>
                        Shop Accessories{' '}
                        <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                      </button>
                    </div>
                  )}
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.parts_order && (
                    <div className="rounded-lg flex flex-col items-start justify-between p-5 gap-4">
                      <div className="flex flex-row gap-4 items-center">
                        <img className="w-10 h-10" src={appImage.CartIcon} alt="" />
                        <div className="flex flex-col">
                          <p className="text-[22px] font-semibold">Order Parts</p>
                          <span className="text-[15px] text-[#898989]">
                            Browse the parts department
                          </span>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                      <button
                        className="cursor-pointer mt-2 relative my-auto mr-0 flex flex-row gap-3 items-center justify-between px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        type="button"
                        onClick={() =>
                          handleOpenLink(rooftopDetail.external_links.parts_order, 'parts_order')
                        }>
                        Order Parts{' '}
                        <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                      </button>
                    </div>
                  )}
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.finance && (
                    <div className="rounded-lg flex flex-col items-start justify-between p-5 gap-4">
                      <div className="flex flex-row gap-4 items-center">
                        <img className="w-10 h-10" src={appImage.CouponIcon} alt="" />
                        <div className="flex flex-col">
                          <p className="text-[22px] font-semibold">Parts Coupons</p>
                          <span className="text-[15px] text-[#898989]">
                            Discounts on Service & Parts
                          </span>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                      <button
                        className="cursor-pointer mt-2 relative my-auto mr-0 flex flex-row gap-3 items-center justify-between px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        type="button"
                        onClick={() =>
                          handleOpenLink(rooftopDetail.external_links.finance, 'parts_coupons')
                        }>
                        Redeem Coupons{' '}
                        <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                      </button>
                    </div>
                  )}
                {rooftopDetail &&
                  rooftopDetail.external_links &&
                  rooftopDetail.external_links.service_url && (
                    <div className="rounded-lg flex flex-col items-start justify-between p-5 gap-4">
                      <div className="flex flex-row gap-4 items-center">
                        <img className="w-10 h-10" src={appImage.TireIcon} alt="" />
                        <div className="flex flex-col">
                          <p className="text-[22px] font-semibold">Tire Center</p>
                          <span className="text-[15px] text-[#898989]">
                            Browse the tires department
                          </span>
                        </div>
                      </div>
                      <div className="border-b w-full" />
                      <button
                        className="cursor-pointer mt-2 relative my-auto mr-0 flex flex-row gap-3 items-center justify-between px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        type="button"
                        onClick={() =>
                          handleOpenLink(rooftopDetail.external_links.service_url, 'tires')
                        }>
                        Shop Tires{' '}
                        <img className="w-4 h-4" src={appImage.ExternalLinkWhite} alt="" />
                      </button>
                    </div>
                  )}
              </>
            )}
          </div>
          {rooftopDetail && rooftopDetail.dignify && (
            <div className="px-[20px] md:px-[74px] mb-[50px] md:mb-[68px] text-[12px] text-[#6B7280]">
              <h3 className="font-bold mb-3">Disclosure</h3>
              <p className="mb-3">
                *All credit products are issued by WebBank. An origination fee of 4% applies. The
                DigniFi Interest Waived Program applies to all approved customers. Interest will be
                charged to your account from account opening at 9.99%‐36.00% APR. If the full loan
                amount is paid within the first 90 days, you will receive a refund in the amount of
                any accrued interest that was charged. A minimum monthly payment is required.
                Additional principal payments will be required to pay off the loan amount before the
                promotion expires. Interest will be waived if you make all of your scheduled
                payments on time and pay off your loan in full within the first 90 days. The
                application generally takes a few minutes. All applications are subject to approval
                pursuant to standard underwriting criteria. The maximum approved amount, terms, and
                the Annual Percentage Rate (APR) will be based on an evaluation of credit history.
                WebBank funds the repair amount once repairs are completed and documents are
                executed. (Depending on program used, an origination fee is 3 (AN) or 4% for
                everyone else)
              </p>
              <p className="mb-3">
                **All DigniFi ExpressWay credit cards are issued by WebBank. An annual fee of up to
                $79 may apply. Six-month deferred interest promotional offer is available on all
                approved purchases of $350 or more using the DigniFi ExpressWay credit card. No
                interest will be charged on the promotional purchase if you pay the promotional
                purchase amount in full within 6 months. If you do not, interest will be charged on
                the promotional purchase from the purchase date. A minimum monthly payment is
                required. The required minimum monthly payments may not pay off the promotional
                purchase by the end of the promotional period. Account must be open and current to
                be eligible for this offer. Regular account terms apply to non-promotional purchases
                and, after promotion ends, to your promotional purchases. Please see any special
                promotion advertising or other disclosures provided to you for the full terms of any
                special promotion offered. All applications for credit are based on an evaluation of
                credit history. The annual percentage rate (APR) ranges from 27.99%–36%. If you are
                charged interest, the charge will be no less than $2.00.
              </p>
              <p className="mb-3">
                † Pricing may vary based on your ExpressWay transaction amount, between "$350
                $7,500", in combination with the monthly term of your Promotional Credit Plan. Your
                final APR will be illustrated on your final purchase receipt, emailed to you at the
                time of the transaction, and located by logging into your account.
              </p>
            </div>
          )}
        </div>
      </main>
    </>
  );
};
export default withUser(PartsAndAccessories);
