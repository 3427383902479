import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import SalesAgent from '../components/SalesReview/SalesAgent';
import withLoader from '../redux/Hoc/withLoader';
import SocialMediaShare from '../components/SalesReview/SocialMediaShare';
import CustomerReviews from '../components/SalesReview/CustomerReviews';
import { getAllReviewsByStaff, getRoofTopById, getStaffById } from '../utils/firebase';
import SalesSliderImages from '../components/SalesReview/SalesSliderImages';
import HeadTags from '../components/SalesReview/HeadTags';

const share = {
  url: window.location.href,
  href: '#'
};

const SalesReview = ({ setLoader }) => {
  const { id } = useParams();
  const [staff, setStaff] = useState({});
  const [reviews, setReview] = useState([]);

  const onReload = async () => {
    setLoader(true);
    const reviewsLists = await getAllReviewsByStaff(id);
    setReview(reviewsLists);
    setLoader(false);
  };

  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const salesAgentData = await getStaffById(id);
      if (isEmpty(salesAgentData)) {
        window.location = '/';
      }

      const roofTopData = await getRoofTopById(salesAgentData.rooftop);
      if (
        Object.prototype.hasOwnProperty.call(salesAgentData, 'disabled') &&
        salesAgentData.disabled
      ) {
        window.location = roofTopData.url.main || '/';
      }

      const reviewsLists = await getAllReviewsByStaff(id);
      const {
        name: { first, last }
      } = salesAgentData;
      salesAgentData.name = `${first} ${last}`;
      salesAgentData.roofTopName = roofTopData.name;
      salesAgentData.rooftopData = roofTopData;
      if (!isEmpty(salesAgentData)) {
        salesAgentData.photos =
          salesAgentData.photos?.length > 0 ? salesAgentData.photos : [roofTopData.location_photo];
      }
      setStaff(salesAgentData);
      setReview(reviewsLists);
      setLoader(false);
    }
    onInit();
  }, []);

  return (
    <>
      <HeadTags
        title={`I had a great experience with ${staff.name}`}
        description=""
        image={
          Object.keys(staff).length > 0 &&
          // eslint-disable-next-line no-prototype-builtins
          staff.hasOwnProperty('photos') &&
          staff.photos?.length > 0
            ? staff.photos[0]
            : ''
        }
        url={`${window.location.origin}/contact/${staff.id}`}
      />
      <main className="lg:col-span-10 xl:col-span-10">
        {/*  <div className="bg-white shadow overflow-hidden sm:rounded-lg"> */}
        <div className="mx-auto py-4 md:py-0 px-4 sm:py-3 lg:max-w-7xl lg:px-8">
          <div
            className={`lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 ${
              Object.keys(staff).length > 0 &&
              // eslint-disable-next-line no-prototype-builtins
              staff.hasOwnProperty('photos') &&
              staff.photos?.length > 0
                ? 'lg:gap-y-10'
                : ''
            } xl:gap-x-16`}>
            <div className="lg:row-end-1 lg:col-span-4">
              <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100">
                <aside className="xl:col-span-4">
                  <div className="sticky top-5 space-y-5">
                    {Object.keys(staff).length > 0 && <SalesSliderImages data={staff.photos} />}
                  </div>
                </aside>
              </div>
            </div>

            <div className="max-w-2xl mx-auto mt-6 md:mt-14 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
              <SalesAgent data={staff} reviewList={reviews} onReload={onReload} isSales={false} />
              <SocialMediaShare data={share} staffData={staff} isSales={false} />
            </div>
            <CustomerReviews data={reviews} />
          </div>
        </div>
        {/*  </div> */}
      </main>
    </>
  );
};

export default withLoader(SalesReview);
