import React, { useEffect, useState } from 'react';
import { CheckIcon, UserIcon, CurrencyDollarIcon } from '@heroicons/react/solid';
import moment from 'moment';
import _, { isEmpty, remove } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { classNames } from '../../helpers/app';
import withUser from '../../redux/Hoc/withUser';
import LeaveReview from '../../modals/LeaveReview';
import { getAccountById, getRoofTopById, getStaffById } from '../../utils/firebase';
import { eventAnalytics } from '../../utils/analytics';
import { CATEGORY, ACTION } from '../../constants';
import appImage from '../../assets/image';

const REVIEW_MODAL = 'REVIEW_MODAL';

const timeline = [
  {
    id: 1,
    content: 'First login to your ',
    target: 'Vinsyt',
    href: '#',
    created_at: '',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
    key: 'welcome',
    onClick: () => {}
  },
  {
    id: 3,
    content: 'Leave a review for ',
    target: '',
    href: '#',
    created_at: false,
    icon: UserIcon,
    iconBackground: 'bg-[#CDCDCD]',
    key: 'leave_review',
    onClick: () => {}
  },
  {
    id: 4,
    content: 'Recommend ',
    target: '',
    href: '#',
    created_at: false,
    icon: CurrencyDollarIcon,
    iconBackground: 'bg-[#CDCDCD]',
    key: 'share_with_ff',
    onClick: () => {}
  }
  /* {
    id: 4,
    content: 'Share to Facebook',
    target: '',
    href: '#',
    created_at: false,
    icon: ShareIcon,
    iconBackground: 'bg-gray-400',
    key: 'share_with_fb',
    onClick: () => {}
  } */
];

const DashboardFeed = ({ user }) => {
  const navigate = useNavigate();
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [userFeed, setUserFeed] = useState(timeline);
  const [staff, setStaff] = useState({});
  const [searchParams] = useSearchParams();
  let redirectTo = '0';
  if (!_.isEmpty(searchParams.get('review'))) {
    redirectTo = searchParams.get('review');
  }

  const onClickItem = async (key) => {
    const staffData = await getStaffById(user.staff);
    if (key === 'leave_review') {
      setReviewModalOpen(true);
      eventAnalytics({
        category: CATEGORY.CHECKLIST,
        action: ACTION.CHK_LEAVE_REVIEW,
        label: `${staffData.name.first} ${staffData.name.last}`,
        nonInteraction: false
      });
    } else if (key === 'share_with_ff') {
      eventAnalytics({
        category: CATEGORY.CHECKLIST,
        action: ACTION.CHK_FRIENDS_FAMILY,
        nonInteraction: false
      });
      navigate('/friends-and-family');
    } else if (key === 'share_with_fb') {
      eventAnalytics({
        category: CATEGORY.CHECKLIST,
        action: ACTION.CHK_SHARE_FACEBOOK,
        label: `${user.name.first} ${user.name.last}`,
        nonInteraction: false
      });
    } else if (key === 'passkit') {
      eventAnalytics({
        category: CATEGORY.CHECKLIST,
        action: ACTION.CHK_PASSKIT,
        label: `${user.name.first} ${user.name.last}`,
        nonInteraction: false
      });
      navigate('/wallet');
    }
  };

  const hideChecklist = async () => {
    remove(userFeed, (feed) => {
      const todayDate = moment().format('YYYY-MM-DD').split('-');
      const feedDate = moment.unix(feed.created_at.seconds).format('YYYY-MM-DD').split('-');
      return feed.key === 'welcome'
        ? moment(todayDate).diff(moment(feedDate), 'days') > 7
        : feed.status && moment(todayDate).diff(moment(feedDate), 'days') > 7;
    });
  };

  useEffect(() => {
    async function onInit() {
      if (user.id) {
        const staffData = await getStaffById(user.staff);
        const accountData = await getAccountById(user.id);
        const roofTopData = await getRoofTopById(user.rooftop);
        if (roofTopData && roofTopData.passkit) {
          const newItem = {
            id: 2,
            content: 'Download your Card',
            target: '',
            href: '#',
            created_at: '',
            customIcon: appImage.WalletWhite,
            iconBackground: 'bg-[#CDCDCD]',
            key: 'passkit',
            onClick: () => {}
          };
          const index = userFeed.findIndex((x) => x.key === 'passkit');
          if (index === -1) {
            userFeed.splice(1, 0, newItem);
          }
        }
        setStaff(staffData);
        let yellowApplied = false;
        let accountCreatedTime = '';
        let isReviewGiven = false;
        userFeed.map((feed, fKey) => {
          if (feed.key === 'welcome') {
            userFeed[fKey].created_at = user.created_on;
            accountCreatedTime = moment(
              moment.unix(userFeed[fKey].created_at.seconds).format('M/D/YYYY'),
              'M/D/YYYY'
            );
          } else if (feed.key === 'leave_review') {
            isReviewGiven = accountData[feed.key].status;
            userFeed[fKey].status = accountData[feed.key].status;
            userFeed[fKey].onClick =
              accountData[feed.key].status === false ? onClickItem : feed.onClick;
            userFeed[fKey].target = `${staffData.name.first} ${staffData.name.last}`;
            userFeed[fKey].content =
              accountData[feed.key].status === false ? feed.content : 'Given a review for ';
            userFeed[fKey].background = accountData[feed.key].status === true ? '' : 'bg-amber-100';
          } else if (feed.key === 'share_with_ff') {
            userFeed[fKey].status = accountData[feed.key].status;
            userFeed[fKey].onClick =
              accountData[feed.key].status === false ? onClickItem : feed.onClick;
            userFeed[fKey].target = `${staffData.name.first} ${staffData.name.last}`;
            userFeed[fKey].content =
              accountData[feed.key].status === false
                ? feed.content
                : 'Shared with Friends & Family ';
          } else if (feed.key === 'share_with_fb') {
            userFeed[fKey].status = accountData[feed.key].status;
            userFeed[fKey].onClick =
              accountData[feed.key].status === false ? onClickItem : feed.onClick;
            userFeed[fKey].content =
              accountData[feed.key].status === false ? feed.content : 'Shared to Facebook ';
          } else if (feed.key === 'passkit') {
            userFeed[fKey].status = accountData[feed.key].status;
            userFeed[fKey].onClick =
              accountData[feed.key].status === false ? onClickItem : feed.onClick;
            userFeed[fKey].content =
              accountData[feed.key].status === false ? feed.content : 'Downloaded my Card ';
          }
          if (!isEmpty(accountData[feed.key])) {
            if (!yellowApplied && accountData[feed.key].status === false) {
              userFeed[fKey].background = 'bg-amber-100';
              yellowApplied = true;
            }
            userFeed[fKey].created_at =
              accountData[feed.key].status === false ? '' : accountData[feed.key].created_on;
            userFeed[fKey].iconBackground =
              accountData[feed.key].status === true
                ? 'bg-green-500'
                : userFeed[fKey].iconBackground;
            userFeed[fKey].icon =
              accountData[feed.key].status === true ? CheckIcon : userFeed[fKey].icon;
          }
        });
        setUserFeed([...userFeed]);
        await hideChecklist(roofTopData);

        // Session storage
        const currentTime = moment(moment().format('M/D/YYYY'), 'M/D/YYYY');
        const diffDays = currentTime.diff(accountCreatedTime, 'days');
        const isReviewModalOpen = sessionStorage.getItem(REVIEW_MODAL);
        if (redirectTo === '1') {
          setReviewModalOpen(true);
        } else if (isEmpty(isReviewModalOpen) && diffDays <= 7 && !isReviewGiven) {
          setTimeout(() => {
            setReviewModalOpen(true);
            sessionStorage.setItem(REVIEW_MODAL, 'true');
          }, 2000);
        }
      }
    }
    onInit();
  }, [user]);

  return (
    <section aria-labelledby="who-to-follow-heading" className="mb-4 md:mb-6 xl:mb-9">
      <div className="">
        <div className="p-6">
          <div className="flow-root">
            <p className="mb-5 text-lg font-medium text-[#333333] border-b border-black pb-1.5">{`${user.name.first}’s Checklist`}</p>
            <ul role="list" className="-mb-8">
              {userFeed.map((event, eventIdx) => (
                <li key={event.id}>
                  <div
                    className="relative pb-8 cursor-pointer"
                    onClick={() => event.onClick(event.key)}>
                    {eventIdx !== timeline.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className={`relative flex space-x-3 ${event.background}`}>
                      <div>
                        {event.customIcon ? (
                          <span
                            className={classNames(
                              event.iconBackground,
                              'h-8 w-8 rounded-full flex items-center justify-center ring-white'
                            )}>
                            <img
                              className={classNames(event.iconBackground, 'h-4 w-4')}
                              src={event.customIcon}
                              alt={event.customIcon}
                            />
                          </span>
                        ) : (
                          <span
                            className={classNames(
                              event.iconBackground,
                              'h-8 w-8 rounded-full flex items-center justify-center ring-white'
                            )}>
                            <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                          </span>
                        )}
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          <p className="text-sm text-gray-500">
                            {event.content}
                            <a href={event.href} className="font-medium text-gray-900">
                              {event.target}
                            </a>
                          </p>
                        </div>
                        {event.created_at && (
                          <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            <p>{moment.unix(event.created_at.seconds).format('MMM DD')}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {reviewModalOpen && (
        <LeaveReview
          onContinue={() => setReviewModalOpen(false)}
          open={reviewModalOpen}
          staff={staff}
        />
      )}
    </section>
  );
};

export default withUser(DashboardFeed);
