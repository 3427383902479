// import ReactGA4 from 'react-ga4';
import GTagManager from 'react-gtm-module';
import { isEmpty } from 'lodash';
import { getConfig, getEnviroment } from '../config';
import { store } from '../redux/store';

const config = getConfig();

export const initAnalytics = (userId = false) => userId;
/* ReactGA4.initialize([
    {
      trackingId: config.firebaseConfig.measurementId,
      gaOptions: {
        userId: userId || 0
      }
    }
  ]); */

export const eventAnalytics = (args) => args; // ReactGA4.event(args);

export const initReactGTMModule = (tagManagerArgs) =>
  GTagManager.initialize({
    gtmId: config.gtmId,
    dataLayer: tagManagerArgs
  });

export const eventReactGTMModule = (data) => {
  const { user } = store.getState();
  GTagManager.initialize({
    gtmId: config.gtmId,
    events: {
      enviroment: getEnviroment(),
      experience: 'owner',
      ownerID: isEmpty(user.id) ? '' : user.id,
      agentID: isEmpty(user.staff) ? '' : user.staff,
      rooftop: isEmpty(user.rooftop) ? '' : user.rooftop,
      dealerGroupID: isEmpty(user.rooftopData.group) ? '' : user.rooftopData.group,
      ...data
    }
  });
};

export const eventReactGTMModuleReferral = (data) => {
  GTagManager.initialize({
    gtmId: config.gtmId,
    events: {
      enviroment: getEnviroment(),
      experience: 'referral',
      ...data
    }
  });
};

export const eventReactGTMModuleSales = (data) => {
  GTagManager.initialize({
    gtmId: config.gtmId,
    events: {
      enviroment: getEnviroment(),
      ...data
    }
  });
};
