/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import ContactCards from '../components/Contacts/ContactCards';
import { getAllSalesByRooftop, getRoofTopById, getStaffById } from '../utils/firebase';
import { eventAnalytics, eventReactGTMModule } from '../utils/analytics';
import { ACTION, CATEGORY, ROLE } from '../constants';
import { USPhoneNumber } from '../helpers/app';
import Finance from '../components/Finance';

const Service = ({ user, setLoader }) => {
  const navigate = useNavigate();
  const [people, setPeople] = useState([]);
  const [rooftop, setRooftop] = useState({});
  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const roles = [ROLE.SERVICE_ADVISOR, ROLE.SERVICE_MANAGER];
      const salesLists = await getAllSalesByRooftop(user.rooftop, roles);
      const roofTopRes = await getRoofTopById(user.rooftop);
      if (salesLists.length > 0) {
        const salesAgentData = salesLists.splice(
          salesLists.findIndex((sales) => sales.id === user.staff),
          1
        );
        salesLists.unshift(salesAgentData[0]);
        setPeople(_.orderBy(salesLists, ['order'], 'asc'));
      }
      if (Object.keys(roofTopRes).length > 0) {
        setRooftop(roofTopRes);
      }
      setLoader(false);
    }
    onInit();
  }, []);

  const onSalesReview = async (index) => {
    const staffData = await getStaffById(user.staff);
    eventAnalytics({
      category: CATEGORY.CONTACT,
      action: ACTION.AGENT_PROFILE,
      label: `${staffData.name.first} ${staffData.name.last}`,
      nonInteraction: false
    });
    navigate(`/contact/${index}`, { replace: true });
  };

  const onClickLinkOrCall = (value, item) => {
    let tagManagerArgs = {
      area: 'service'
    };
    if (value === 'call') {
      tagManagerArgs = {
        ...tagManagerArgs,
        event: 'e_dealer_contact',
        contact_method: 'call',
        contact_type: 'service'
      };
      eventReactGTMModule(tagManagerArgs);
    } else {
      tagManagerArgs = {
        ...tagManagerArgs,
        event: 'e_schedule_appointment',
        external_destination: 'service'
      };
    }
    eventReactGTMModule(tagManagerArgs);
    window.open(item);
  };

  let rooftopPhone = {};
  if (Object.keys(rooftop).length > 0) {
    rooftopPhone = rooftop.phone;
  }

  return (
    <>
      <main className="lg:col-span-9 xl:col-span-10">
        <div className="bg-gray-50 rounded-lg">
          <div className="rounded-lg bg-white pb-12 shadow">
            <div className="relative z-0">
              <div className="absolute inset-0 bg-cover bg-center bg-service-cover rounded-t-lg h-3/5 md:h-4/5 w-full">
                <div className="pt-5 px-4 sm:px-6 lg:px-8">
                  <div className="text-center">
                    <p className="mt-3 md:mt-12 ml-2.5 text-left font-extrabold text-[#333333] text-2xl lg:text-4xl font-['Inter, ExtraBold']">
                      Service
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="relative pt-20 md:pt-40">
                  <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-lg shadow">
                    <div className="-ml-4 -mt-4 flex flex-col md:flex-row justify-center sm:justify-between items-center flex-wrap sm:flex-nowrap">
                      <div className="ml-4 mt-4">
                        <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-3">
                          Schedule an Appointment today
                        </h3>
                        <p className="text-sm max-w-[475px]">
                          {`Trust ${rooftop.name} for quality service, maintenance, or repair.
                              Nobody knows your vehicle better than our certified service experts.`}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center mx-auto mt-8 sm:mt-4 flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => {
                            onClickLinkOrCall('link', rooftop.external_links.service_url);
                          }}
                          className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          Schedule an Appointment
                        </button>
                        <div className="mt-2 mx-auto">
                          <span className="mt-9 text-sm font-normal text-gray-500">Or Call</span>
                          <span
                            onClick={() => {
                              onClickLinkOrCall('call', `tel:${rooftopPhone.sales}`);
                            }}
                            className="cursor-pointer mt-1 text-sm sm:mt-0 sm:col-span-2 text-[#5046E4]">
                            <span className="ml-1">{USPhoneNumber(rooftopPhone.sales)}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {rooftop && rooftop.dignify && (
              <div className="md:px-[32px]  md:pt-[16px] ">
                <Finance
                  shadow={false}
                  rooftop={rooftop}
                  // customClassPending="md:px-[32px]  md:pt-[16px] bg-white rounded-lg"
                  // customClassApproved="md:px-[32px]  md:pt-[16px] bg-white rounded-lg"
                />
              </div>
            )}

            {/* <div className="relative bg-white px-4 py-5 border-b border-gray-200 sm:px-8 rounded-lg shadow mx-4 md:mx-8 mt-3 md:mt-12 mt-5 md:mt-8 mb-14 grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-8">
                <h3 className="text-base md:text-lg leading-6 font-semibold text-gray-900 mb-2 md:mb-3">
                  Current Service & Parts Specials
                </h3>
                <p className="text-sm max-w-[475px]">
                  Pay less for certified Ford service with monthly maintenance {rooftop.name} in
                  Page, AZ. Check the current offers, download savings to your smartphone, and show
                  your service advisor upon arrival.
                </p>
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-row justify-start md:justify-end items-start">
                <button
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button">
                  View Latest Specials
                </button>
              </div>
            </div> */}

            <section aria-labelledby="comparison-heading">
              <div className="relative max-w-2xl mx-auto px-2 lg:max-w-5xl lg:px-4">
                <p className="mt-3 ml-4 text-xl text-[#6B7280]">Meet our service managers</p>
                <p className="mt-3 ml-4 border-b mr-7" />
              </div>
              <main className="mt-5 lg:col-span-12 xl:col-span-10 ml-8 mr-10">
                {people.length > 0 ? (
                  <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    <ContactCards
                      data={people}
                      onSalesReview={onSalesReview}
                      user={user}
                      event="e_service_contact"
                      area="service"
                    />
                  </ul>
                ) : (
                  <p className="mt-10 sm:mt-20 text-center">No service manager available.</p>
                )}
              </main>
            </section>
            {rooftop && rooftop.dignify && (
              <div className="px-[20px] md:px-[74px] mb-[50px] md:mb-[68px] text-[12px] text-[#6B7280] pt-[70px]">
                <h3 className="font-bold mb-3">Disclosure</h3>
                <p className="mb-3">
                  *All credit products are issued by WebBank. An origination fee of 4% applies. The
                  DigniFi Interest Waived Program applies to all approved customers. Interest will
                  be charged to your account from account opening at 9.99%‐36.00% APR. If the full
                  loan amount is paid within the first 90 days, you will receive a refund in the
                  amount of any accrued interest that was charged. A minimum monthly payment is
                  required. Additional principal payments will be required to pay off the loan
                  amount before the promotion expires. Interest will be waived if you make all of
                  your scheduled payments on time and pay off your loan in full within the first 90
                  days. The application generally takes a few minutes. All applications are subject
                  to approval pursuant to standard underwriting criteria. The maximum approved
                  amount, terms, and the Annual Percentage Rate (APR) will be based on an evaluation
                  of credit history. WebBank funds the repair amount once repairs are completed and
                  documents are executed. (Depending on program used, an origination fee is 3 (AN)
                  or 4% for everyone else)
                </p>
                <p className="mb-3">
                  **All DigniFi ExpressWay credit cards are issued by WebBank. An annual fee of up
                  to $79 may apply. Six-month deferred interest promotional offer is available on
                  all approved purchases of $350 or more using the DigniFi ExpressWay credit card.
                  No interest will be charged on the promotional purchase if you pay the promotional
                  purchase amount in full within 6 months. If you do not, interest will be charged
                  on the promotional purchase from the purchase date. A minimum monthly payment is
                  required. The required minimum monthly payments may not pay off the promotional
                  purchase by the end of the promotional period. Account must be open and current to
                  be eligible for this offer. Regular account terms apply to non-promotional
                  purchases and, after promotion ends, to your promotional purchases. Please see any
                  special promotion advertising or other disclosures provided to you for the full
                  terms of any special promotion offered. All applications for credit are based on
                  an evaluation of credit history. The annual percentage rate (APR) ranges from
                  27.99%–36%. If you are charged interest, the charge will be no less than $2.00.
                </p>
                <p className="mb-3">
                  † Pricing may vary based on your ExpressWay transaction amount, between "$350
                  $7,500", in combination with the monthly term of your Promotional Credit Plan.
                  Your final APR will be illustrated on your final purchase receipt, emailed to you
                  at the time of the transaction, and located by logging into your account.
                </p>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default withUser(withLoader(Service));
